import { useSelector } from "react-redux"
import MagicButton from "components/MagicButton/MagicButtonV2"
import { useDispatch } from "react-redux"
import "views/shoppingCart/index.scss"
import { useEffect } from "react"
import CustomSelect from "components/CustomSelect"
import "./OnBehalf.scss"
//import useMagicForm from 'utils/useMagicForm';
import { loadItem } from "store/justActions/loadApiIems"
import { apiAction } from "store/middleware/api"
import { loadViewData } from "store/modules/dataView/dataView-dataLoader"
import { getCartDeliveryAddress } from "store/justActions/miscApiInteractions"
import { changeDeliveryAddressInShop } from "store/modules/shop"
import { showMagicToaster } from "utils/MagicToasterCall"
import { setDictionary } from "store/modules/backData"
import { printError } from "utils/printError"
import { setOnBehalfAddressList, setOnBehalfIdInState } from "store/modules/page"

const changeClient = (clientId) => {
  return apiAction({
    endPoint: `/organization/webshop-client/client-change/${clientId}`,
    method: "POST",
    data: {},
    onSuccess: (data, dispatch) => {
      let activeAddress = data?.addressList?.filter?.(
        (element) => element?.id === data?.defaultAddressId
      )
      dispatch(setOnBehalfAddressList(data?.addressList))
      dispatch(setOnBehalfIdInState(clientId))
      dispatch(
        changeDeliveryAddressInShop(
          data?.defaultAddressId,
          activeAddress?.[0]?.displayAddress
        )
      )
      //dispatch(setDictionary({selectedCompany:data?.name}))
      showMagicToaster({
        title: `Compania ${data?.name} a fost selectată cu succes.`
      })
      return {
        type: "a"
      }
    },
    onFailure: (error, dispatch) => {
      printError("changeClient()", error)
      showMagicToaster({
        title: "A aparut o eroare la salvarea datelor",
        subtitle: "E201",
        type: "error"
      })
      return {
        type: "a"
      }
    }
  })
}

const OnBehalf = (props) => {
  //const { submitForm, disableSubmit, getProps, setEdit, errors, watch, reset, cancelEdit, isEdit } = useMagicForm({ formName: "OnBehalf" });
  const dispatch = useDispatch()

  const crtFormItem = useSelector((state) => state?.page?.crtFormItem)
  const productCount = useSelector(
    (state) => state.shop?.cartData?.productCount
  )
  //const selectedCompany = useSelector(state => state.backData?.dictionary?.selectedCompany)
  //const lof = watch('allClientList')
  const lof = props.watch("allClientList")

  useEffect(() => {
    dispatch(loadItem("organization/webshop-client/my-options", "", true, true))
    // if(selectedCompany) {
    //   document.getElementsByClassName('crs__placeholder css-1wa3eu0-placeholder')[0].innerText = 'dadas'
    // }
  }, [])

  useEffect(() => {
    dispatch(getCartDeliveryAddress(lof))
  }, [lof])

  const helper = () => {
    dispatch(changeClient(lof))
    setTimeout(() => dispatch(loadViewData({ reload: true })), 500)
  }

  return (
    <div data-test-id='ZuDi' className={`delivery-notification-card-on-behalf`}>
      <CustomSelect
        {...props.getProps("allClientList")}
        className="on-behalf-select"
        inForm
        type="filter"
        disabled={productCount > 0}
        options={crtFormItem?.allClientList}
      />
      <MagicButton
        ml={props?.showLongText ? 18 : 2}
        w={40}
        h={7.25}
        label={"Aplica"}
        onClick={helper}
        disabled={lof === "" || productCount > 0}
      />
      <MagicButton
        ml={2}
        w={40}
        h={7.25}
        label={"Mergi în ADMIN"}
        url="/admin"
      />
    </div>
  )
}

export default OnBehalf
