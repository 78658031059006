import React from "react"
import ReactDOM from "react-dom"

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "./index.css"
import "./style/index.scss"
// import '../node_modules/carbon-components/scss/globals/scss/styles.scss';
import App from "App"
import reportWebVitals from "reportWebVitals"
import { Provider } from "react-redux"
import store, { persistor } from "store"
import { PersistGate } from "redux-persist/integration/react"
import ConfirmActionModal from "components/_modals/ConfirmActionModal"
import ReduxToastr from "react-redux-toastr"
import { LocalizeProvider } from "react-localize-redux"
import LoadLocalization from "components/Lang/Lang"

// Supports weights 100-900
import '@fontsource-variable/montserrat';
// Supports weights 300-800
import '@fontsource-variable/open-sans/wdth.css';
import '@fontsource/ibm-plex-mono/100-italic.css';
import '@fontsource/ibm-plex-mono/200-italic.css';
import '@fontsource/ibm-plex-mono/300-italic.css';
import '@fontsource/ibm-plex-mono/400-italic.css';
import '@fontsource/ibm-plex-mono/500-italic.css';
import '@fontsource/ibm-plex-mono/600-italic.css';
import '@fontsource/ibm-plex-mono/700-italic.css';

import '@fontsource/ibm-plex-sans/100-italic.css';
import '@fontsource/ibm-plex-sans/200-italic.css';
import '@fontsource/ibm-plex-sans/300-italic.css';
import '@fontsource/ibm-plex-sans/400-italic.css';
import '@fontsource/ibm-plex-sans/500-italic.css';
import '@fontsource/ibm-plex-sans/600-italic.css';
import '@fontsource/ibm-plex-sans/700-italic.css';




const toastrProps = {
  timeOut: 10000,
  newestOnTop: false,
  preventDuplicates: true,
  position: "bottom-left",
  getState: (state) => state?.toastr, // This is the default
  transitionIn: "fadeIn",
  transitionOut: "fadeOut",
  // progressBar
  closeOnToastrClick: true
}

if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://b71da713e9b14c709463f76733568eb8@o4504293326585856.ingest.sentry.io/4504315725873152",
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: 'V-0.7.81',
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  // process.env.REACT_APP_SENTRY_ENV
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LocalizeProvider store={store}>
          <LoadLocalization />

          <App />
          <ReduxToastr {...toastrProps} />
          <ConfirmActionModal />
        </LocalizeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
