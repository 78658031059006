import SvgIcon from "components/SvgIcon/SvgIcon"
import { useSelector } from "react-redux"
import MagicButton from "components/MagicButton/MagicButtonV2"
import { useDispatch } from "react-redux"
import "./DeliveryNotificationCard.scss"
import BigModal from "components/_modals/BigModal"
import { shoppingCartSelector } from "store/selectors/dataView/dataView.productsListFinal.selector"
import { useState } from "react"
import { changeDeliveryAddressInShop } from "store/modules/shop"
import { getCartDeliveryAddress } from "store/justActions/miscApiInteractions"
import "views/shoppingCart/index.scss"
import { useEffect } from "react"
import { Lang, lang, Translate, withLocalize } from "components/Lang/Lang"
import DepotMovedDisclaimer from "components/DepotMovedDisclaimer"
function DeliveryNotificationCard(props) {
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState()
  const [selectedDeliveryAddressName, setSelectedDeliveryAddressName] =
    useState()
  let final

  const crtFormItem = useSelector((state) => state?.page?.crtFormItem)
  const { cartData, addressList } = useSelector((state) =>
    shoppingCartSelector(state)
  )
  const pageModal = useSelector((state) => state.page?.modal)
  const productCount = useSelector(
    (state) => state.shop?.cartData?.productCount
  )
  const finalDeliveryAddress = useSelector(
    (state) => state.backData?.dictionary?.selectedDeliveryAddressName
  )
  const userPermissions = useSelector(
    (state) => state.auth?.userData?.role?.permissions
  )
  const items = useSelector((state) => state?.dataView?.items)
  const lof = props.watch("allClientList")
  const isDisabled = userPermissions?.includes?.("CLIENT_CHANGE_WRITE")
    ? lof === ""
    : false
  useEffect(() => {
    if (
      pageModal === "choose-address" &&
      !userPermissions?.includes?.("CLIENT_CHANGE_WRITE")
    )
      dispatch(getCartDeliveryAddress())
  }, [pageModal])

  const dispatch = useDispatch()

  let activeAddress = crtFormItem?.addressList?.filter?.(
    (element) => element?.id === crtFormItem?.defaultAddressId
  )

  if (!activeAddress) return null

  if (finalDeliveryAddress) {
    final = finalDeliveryAddress
  } else {
    final = activeAddress?.[0]?.displayAddress
  }

  const helper = (id, displayAddress) => {
    setSelectedDeliveryAddress(id)
    setSelectedDeliveryAddressName(displayAddress)
  }

  const deliveryModalAddressCard = (item, index) => (
    <div 
      data-test-id='jMkW'
      key={`livrare-${index}`}
      className={`delivery-address-card ${
        (
          selectedDeliveryAddress
            ? selectedDeliveryAddress === item?.id
            : cartData?.deliveryAddressId === item?.id
        )
          ? "selected"
          : ""
      }`}
      onClick={() =>
        index !== undefined ? helper(item?.id, item?.displayAddress) : undefined
      }
    >
      <div data-test-id='SgCR' className={`title ptype-5-strong text-light-01 mb1`}>
        {item?.name ? item?.name : "-"}
      </div>

      <div data-test-id='GER1' className={`ptype-3 text-light-02 mb4`}>
        {item?.displayAddress ? item?.displayAddress : "-"}
      </div>
    </div>
  )

  const addressSaveAction = () => {
    dispatch(
      changeDeliveryAddressInShop(
        selectedDeliveryAddress || cartData?.deliveryAddressId,
        selectedDeliveryAddressName
      )
    )
  }

  const chooseDeliveryModal = (
    <BigModal
      grid
      name="choose-address"
      title={<Lang value="deliverynotificationcard.chooseAdress" />}
      saveAction={addressSaveAction}
    >
      <div data-test-id='SnVa' className={`delivery-notification-card-in-modal`}>
        <div data-test-id='SxV9' className="mr4">
          <SvgIcon name={"warning"} />
        </div>
        <div data-test-id='ddi2' className={`content-container`}>
          <div data-test-id='71vq'>
            <span data-test-id='PTNA'>
              <Lang value="deliverynotificationcard.changeAdress" />
            </span>
          </div>
        </div>
      </div>
      {addressList?.length
        ? addressList?.map?.((item, i) => deliveryModalAddressCard(item, i))
        : []?.map?.((item, i) => deliveryModalAddressCard(item, i))}
    </BigModal>
  )

  return (
    <>
      <DepotMovedDisclaimer marginBottom='1.5rem'/>
      <div data-test-id='TSQZ' className={`delivery-notification-card-shop`}>
        <div data-test-id='SvWW' className={`content-container`}>
          <div data-test-id='01LB'>
            <SvgIcon name={"warning"} />
          </div>
          <div data-test-id='xIeX'>
            {props?.showLongText ? (
              <>
                {userPermissions?.includes?.("CLIENT_CHANGE_WRITE") &&
                items?.length === 0 ? (
                  <span data-test-id='k4gF'>
                    <Lang value="deliverynotificationcard.chooseCompany" />
                  </span>
                ) : (
                  <>
                    <span data-test-id='4sow'>
                      <Lang value="deliverynotificationcard.deliveryAddress" />{" "}
                    </span>
                    <span data-test-id='06Mn'>{final ?? ""}.</span>
                    <span data-test-id='uetH' className="color-alert-danger ptype-4-bold">
                      <Lang value="deliverynotificationcard.stock" />{" "}
                    </span>
                    <span data-test-id='CeGR'>
                      <Lang value="deliverynotificationcard.otherCity" />{" "}
                    </span>
                  </>
                )}
              </>
            ) : (
              <span data-test-id='KlyZ' className="color-alert-danger ptype-4-bold">
                <Lang value="deliverynotificationcard.stock" />{" "}
              </span>
            )}
          </div>
        </div>
        <MagicButton
          ml={props?.showLongText ? 18 : 0}
          w={props?.showLongText ? 40 : 0}
          h="a"
          label={
            productCount >= 1 ? (
              <Lang value="deliverynotificationcard.changeAddressCart" />
            ) : (
              <Lang value="deliverynotificatoncard.changeAddressDelivery" />
            )
          }
          showModal="choose-address"
          disabled={
            productCount >= 1 ||
            addressList?.length <= 1 ||
            isDisabled ||
            items?.length === 0
          }
        />
        {chooseDeliveryModal}
      </div>
    </>
  )
}

export default DeliveryNotificationCard
