import { printError } from "utils/printError"
import { LOGOUT_USER } from "store/modules/auth"



const getLocalStorageKeys = () => {
  let keys = []

  try {
    
    const nrKeys = localStorage?.length || 0
    for (let i = 0; i < nrKeys; i++) {
      const key = localStorage?.key(i)
      if(key) keys?.push(key)
    }
    return keys

  } catch (error) {
    return keys
  }
  
}


/**
 * Clears all old redux-persist keys from local storage.
 * Meant to be called when the app is loaded.
 * 
 * Every time the app is updated with a new version number, 
 * a brand new redux store should be created, discarding the
 * old persisted redux store. 
 */
export const clearOldReduxPersistence = (dispatch) => {
  const keys = getLocalStorageKeys()
  const reduxPersistenceKeys = keys?.filter?.(key => key?.startsWith?.('persist:'))
  const currentVersion = 'persist:V-0.7.81'
  let shouldLogoutUser = false

  reduxPersistenceKeys?.forEach?.(key => {

    if(key !== `${currentVersion}`) {
      shouldLogoutUser = true
      console.log('Deleting old redux persistence key ', key, ' in favor of the currentVersion: ', currentVersion)
      localStorage?.removeItem?.(key)
    }
  })
  if(shouldLogoutUser) 
    dispatch({ type: LOGOUT_USER })
}




export const getTokenFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('user')
    if(!serializedState) return null
    return JSON.parse(serializedState)?.IdToken
  } catch (err) {
    printError('getTokenFromLocalStorage()', err)
    return null
  }
}
