
import { useSelector } from 'react-redux'
import { campaignDateInfo, isCampaignActive } from 'utils/dateUtils'
import { shoppingCartSelector } from 'store/selectors/dataView/dataView.productsListFinal.selector'
import blackFridayImage from 'assets/Campaign/blackFriday.webp'

import black_friday_dashboard_slide from 'assets/Campaign/black_friday_dashboard_slide.png'
import black_friday_homepage_slide from 'assets/Campaign/black_friday_homepage_slide.png'
import black_friday_dashboard_slide2 from 'assets/Campaign/black_friday_dashboard_slide2.png'
import black_friday_homepage_slide2 from 'assets/Campaign/black_friday_homepage_slide2.png'

import useIsLogged from 'utils/useIsLogged'


const useCampaignInfo = () => {
  
  const campaignApiInfo = useSelector(state => state?.shop?.campaignApiInfo)
  const shoppingCart = useSelector(shoppingCartSelector)
  const isShoppingCartEmpty = !Boolean(shoppingCart?.products?.[0]?.length)
  const isLogged = useIsLogged()

  const getShouldShowCampaignCheckbox = () => {
      if(!isCampaignActive()) return false
      if(!campaignConfig.doesAffectCart) return false
      if(isShoppingCartEmpty) return false
      if(campaignApiInfo?.isCompanyInCampaign) return false
      return true
  }

  const getInitialCampaignCheckboxState = () => {
      if(!isCampaignActive()) return false
      if(campaignApiInfo?.isUserInCampaign) return true
      return false
  }

  const getShouldShowFloatingButton = () => {
    if(isLogged) return false
    // The bloating button must be active DURING and AFTER the campaign.
    return !campaignDateInfo.isBeforeCampaign
  }

  return {
    /** campaignApiInfo?.isUserInCampaign is a misleading name. 
     * It actually becomes false if the user REFUSED the campaign 
     * in the past. Meant to be used to decide the initial state 
     * of the campaign-related checkbox. */ 
    initialCampaignCheckboxState: getInitialCampaignCheckboxState(),
    shouldShowCampaignCheckbox: getShouldShowCampaignCheckbox(), 
    shouldShowFloatingButton: getShouldShowFloatingButton(),

  }

}


/**
 * The campaign ended but SOME of its implemented functionalities 
 * must stay active for some more time. This function is the 
 * decision point for those.
 */
export const isCampaignPartiallyActive = () => 
  true

const getBannerUrl = () => {
  if(campaignDateInfo.isBeforeCampaign) return ''

  return campaignDateInfo.isCampaignActive 
    ? 'https://uniprest.ro/black-friday/' 
    : 'https://uniprest.ro/promotii/'
  
}

const getFloatingUrl = () => {
  return campaignDateInfo.isCampaignActive
    ? '/shop/productlist?filters=%7B"FLT-CATEG001"%3A%5B"%2327616"%5D%7D&page=1&sortBy=ranking'
    : 'https://uniprest.ro/promotii/'
}


export const campaignConfig = {
  url: getBannerUrl(),
  floatingButtonUrl: getFloatingUrl(),
  name: campaignDateInfo.isCampaignActive ? 'Black Friday' : 'Promoții',
  doesAffectRouting: false, 
  doesAffectCart: false,
  imageUrl: blackFridayImage,
  homepageSlideImage: campaignDateInfo.isBeforeCampaign ? black_friday_homepage_slide : black_friday_homepage_slide2,
  dashboardSlideImage: campaignDateInfo.isBeforeCampaign ? black_friday_dashboard_slide : black_friday_dashboard_slide2,
  homepageLocalizationKey: campaignDateInfo.isBeforeCampaign ? 'home.slider2_black_friday_description' : 'home.slider2_black_friday_description2',
  dashboardLocalizationKey: campaignDateInfo.isBeforeCampaign ? 'dashboard.blackFridayLabel' : 'dashboard.blackFridayLabel2',
  hasNarrowDashboardBanner: false,
  isBeforeCampaign: campaignDateInfo.isBeforeCampaign,
  isAfterCampaign: campaignDateInfo.isAfterCampaign,
  isCampaignActive: campaignDateInfo.isCampaignActive,
  isHomepageSlideActive: campaignDateInfo.isCampaignActive || campaignDateInfo.isBeforeCampaign,
  shouldHideOtherHomepageSlides: campaignDateInfo.isCampaignActive || campaignDateInfo.isBeforeCampaign,
  shouldInsertDashboardSlide: campaignDateInfo.isCampaignActive || campaignDateInfo.isBeforeCampaign,
  shouldReplaceDashboardSlider: false, 
  shouldDisplayNonLoggedPrices: campaignDateInfo.isCampaignActive,
  shouldHideStockLiquidationCatFilter: campaignDateInfo.isCampaignActive,
  shouldHideBlackFridayCatFilter: !campaignDateInfo.isCampaignActive,
  shouldDarkenFloatingButton: campaignDateInfo.isCampaignActive,
  shouldShowBreadcrumbExplanation: campaignDateInfo.isCampaignActive,
}

export default useCampaignInfo


export const GoToCampaign = () => {

  window.location.href = 'https://uniprest.ro/black-friday/'

  return null

}