import SvgIcon from "components/SvgIcon/SvgIcon"
import { Lang } from "components/Lang/Lang"

import { useShouldShowDepotMovedDisclaimer } from "store/hooks/useShouldShowDepotMovedDisclaimer"

const style = {
  padding: '1.5rem',
  backgroundColor: 'white',
  border: '1px solid #ff9800',
  display: 'flex',
  flexDirection: 'row',
  columnGap: '1.5rem',
}

const DepotMovedDisclaimer = ({ marginBottom = 0 }) => {

  const shouldShowMovedDisclaimer = useShouldShowDepotMovedDisclaimer()

  if(!shouldShowMovedDisclaimer) return null

  return (
    <div className='warehouseMovedDisclaimer' style={{...style, marginBottom}}>
      <SvgIcon
        name="bell"
        style={{
          width: '56px',
          alignSelf: 'center',
          color: '#ed6c02', 
          stroke: '#ed6c02',
        }}
      />
      <Lang value="dashboard.warehouseMovedDisclaimer" />
    </div>
  )

}

export default DepotMovedDisclaimer
